import React from 'react'
import "./cs.css"
const Cs = () => {
  return (
    <div className='main-div-cs'>

<h1 className='cs-title'>
Coming Soon !!!
</h1>
        
      
    </div>
  )
}

export default Cs
