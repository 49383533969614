import React from 'react'
import ContentSwitcher from './components/ContentSwitcher'
const Gallery = () => {
  return (
    <div>
      <ContentSwitcher />
    </div>
  )
}

export default Gallery
